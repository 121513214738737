import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BifIconPlus, BifIconTrash, Button, OverlayTrigger, Stack, Tooltip } from "banafo-design-system";
import DropdownMenu from "src/features/conversations/DropdownMenu";
import { useTranslation } from "react-i18next";
import { clearSelectedIds, selectCombinedConversationIds, selectCombinedConversationsResult, selectFilters, selectPage, selectSelectedIds, setSelectedIds, showBulkDeleteModal, showUploadModal } from "src/features/conversations/conversationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { CONVERSATIONS_PAGINATION_LIMIT } from "src/features/conversations/config";
import { ActionIcon, Checkbox } from "@mantine/core";
const TableFilters = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const page = useSelector(selectPage);
    const searchFilters = useSelector(selectFilters);
    const perPage = CONVERSATIONS_PAGINATION_LIMIT;
    const start = page * perPage;
    const selectedIds = useSelector(selectSelectedIds);
    const combinedConversationIds = useSelector(state => selectCombinedConversationIds(state, { start, filters: searchFilters }));
    const { data: { total } } = useSelector((state) => selectCombinedConversationsResult(state, { start, filters: searchFilters }));
    const handleShowUploadModal = () => {
        dispatch(showUploadModal());
    };
    return (_jsxs(Stack, { direction: 'horizontal', gap: 100, children: [_jsx(Checkbox, { radius: "xxs", size: "xs", wrapperProps: { 'ml': 'xxs' }, indeterminate: !!selectedIds.length && selectedIds.length < combinedConversationIds.length, checked: !!selectedIds.length, onChange: (e) => {
                    e.currentTarget.checked ? dispatch(setSelectedIds(combinedConversationIds)) : dispatch(clearSelectedIds());
                } }), _jsxs("h6", { className: 'text-uppercase fw-bold m-0', children: [t('Conversations_'), " (", total, ")"] }), _jsx(DropdownMenu, {}), !!selectedIds.length &&
                _jsx(ActionIcon, { variant: "transparent", "aria-label": "Delete", loading: false, onClick: () => {
                        dispatch(showBulkDeleteModal());
                    }, children: _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "delete-conversation", children: t('Delete.selected.conversations_') }), children: _jsx("span", { children: _jsx(BifIconTrash, {}) }) }) }), _jsxs(Button, { variant: 'danger', size: 'sm', className: 'ms-auto text-uppercase with-icon start', onClick: handleShowUploadModal, children: [_jsx(BifIconPlus, {}), " ", t('Upload_')] })] }));
};
export default TableFilters;
