import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Conversations from "src/features/conversations/Conversations";
import UploadRecordingModal from "src/features/uploadRecording/modals/UploadRecordingModal";
import DeleteCallModal from "src/features/conversations/modals/DeleteCallModal";
import EditCallModal from "src/features/conversations/modals/EditModal";
import CreateContactModal from "src/features/conversations/modals/CreateContactModal";
import ExistingContactModal from "src/features/conversations/modals/ExistingContactModal";
import Header from "./Header";
import { Layout } from "banafo-design-system";
import UpgradeBanner from "src/features/account/UpgradeBanner";
import useAccount from "src/features/account/hooks/useAccount";
import BulkDeleteCallsModal from "src/features/conversations/modals/BulkDeleteCallsModal";
const ConversationsPage = () => {
    const { subscriptionPlan: { isFree } } = useAccount();
    return (_jsxs(_Fragment, { children: [_jsx(Layout, { header: _jsx(Header, {}), footer: isFree && _jsx(UpgradeBanner, {}), scrollBar: 1, options: {
                    contentProps: { className: "p-200" },
                    footerProps: { className: "sticky-bottom p-200 pt-0" },
                }, children: _jsx(Conversations, {}) }), _jsx(UploadRecordingModal, {}), _jsx(DeleteCallModal, {}), _jsx(BulkDeleteCallsModal, {}), _jsx(EditCallModal, {}), _jsx(CreateContactModal, {}), _jsx(ExistingContactModal, {})] }));
};
export default ConversationsPage;
