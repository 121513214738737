import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useAccount from "src/features/account/hooks/useAccount";
import PbxUpgradeScreen from "src/features/pbx/PbxUpgradeScreen";
import SettingsForm from "src/features/pbx/SettingsForm";
const PbxSetup = () => {
    const { subscriptionPlan: { isFree } } = useAccount();
    return (_jsxs(_Fragment, { children: [isFree && _jsx(PbxUpgradeScreen, {}), !isFree &&
                _jsx(_Fragment, { children: _jsx(SettingsForm, {}) })] }));
};
export default PbxSetup;
