var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Table } from "banafo-design-system";
import { useDispatch, useSelector } from "react-redux";
import SortableCol from "src/common/components/ui/SortableCol";
import CallsRow from "src/features/conversations/CallsRow";
import { changeSortFilter, selectFilters } from "src/features/conversations/conversationsSlice";
const CallsTable = ({ calls, }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);
    const handleFilterChange = (name) => () => {
        dispatch(changeSortFilter(name));
    };
    return (_jsxs(Table, { responsive: true, hover: true, children: [_jsx("thead", { children: _jsxs("tr", { className: "d-none d-md-table-row", children: [_jsx("td", {}), _jsx("td", {}), _jsx("td", { children: _jsx(SortableCol, { sort: handleFilterChange('title'), sortType: filters['sort[title]'], children: t('Title_') }) }), _jsx("td", { children: _jsx(SortableCol, { sort: handleFilterChange('type'), sortType: filters['sort[type]'], children: t('Type_') }) }), _jsx("td", { children: _jsx(SortableCol, { sort: handleFilterChange('startedAt'), sortType: filters['sort[startedAt]'], children: t('Started.At_') }) }), _jsx("td", { children: _jsx(SortableCol, { sort: handleFilterChange('userName'), sortType: filters['sort[userName]'], children: t('User_') }) }), _jsx("td", { children: _jsx(SortableCol, { sort: handleFilterChange('contact.displayName'), sortType: filters['sort[contact.displayName]'], children: t('Contact_') }) }), _jsx("td", { children: _jsx(SortableCol, { sort: handleFilterChange('duration'), sortType: filters['sort[duration]'], children: t('Duration_') }) }), _jsx("td", { className: "actions", children: t('Actions_') })] }) }), _jsx("tbody", { children: calls.map((_a) => {
                    var { id } = _a, call = __rest(_a, ["id"]);
                    return _jsx(CallsRow, { call: Object.assign({ id }, call) }, id);
                }) })] }));
};
export default CallsTable;
