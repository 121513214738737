import i18n from "src/app/services/i18n";
import nav1 from "./images/nav1.svg";
import nav2 from "./images/nav2.svg";
import nav5 from "./images/nav5.svg";
import nav6 from "./images/nav6.svg";
import nav7 from "./images/nav7.svg";
import nav8 from "./images/nav8.svg";
import { HELP_URL_FULL } from "src/app/config";
import { API_KEYS_ROUTE, CONTACTS_ROUTE, CONVERSATIONS_ROUTE, DEVICE_ACTIVATE_ROUTE, DEVICE_ACTIVATE_ANDROID_ROUTE, DEVICE_ACTIVATE_ZOIPER_ANDROID_ROUTE, DEVICE_ACTIVATE_ZOIPER5_ROUTE, HOME_ROUTE, INTEGRATIONS_ROUTE, RECORDING_EXTENSION_ROUTE, SETTINGS_ROUTE, RESELLER_CUSTOMERS_ROUTE, RESELLER_CUSTOMERS_SETTINGS_ROUTE, RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE, RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE, RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE, PBX_ROUTE, SETTINGS_GUIDE_3CX_ROUTE } from "src/app/router/config";
export const SIDEBAR_MENU_ITEMS = [
    {
        key: 'home',
        icon: nav1,
        get title() { return i18n.t('Home_'); },
        href: HOME_ROUTE,
        routes: [{ path: HOME_ROUTE }],
        subitems: []
    },
    {
        key: 'calls',
        icon: nav2,
        get title() { return i18n.t('Conversations_'); },
        href: CONVERSATIONS_ROUTE,
        routes: [{ path: CONVERSATIONS_ROUTE }],
        subitems: []
    },
    {
        key: 'contacts',
        icon: nav8,
        get title() { return i18n.t('Contacts_'); },
        href: CONTACTS_ROUTE,
        routes: [{ path: CONTACTS_ROUTE }],
        subitems: []
    },
    {
        key: 'configuration',
        icon: nav5,
        get title() { return i18n.t('Configuration_'); },
        href: SETTINGS_ROUTE,
        routes: [
            { path: SETTINGS_ROUTE }, { path: PBX_ROUTE }, { path: SETTINGS_GUIDE_3CX_ROUTE }, { path: INTEGRATIONS_ROUTE }, { path: API_KEYS_ROUTE },
            { path: RECORDING_EXTENSION_ROUTE }, { path: DEVICE_ACTIVATE_ROUTE }, { path: DEVICE_ACTIVATE_ANDROID_ROUTE },
            { path: DEVICE_ACTIVATE_ZOIPER_ANDROID_ROUTE }, { path: DEVICE_ACTIVATE_ZOIPER5_ROUTE }
        ],
        subitems: [
            {
                key: "settings",
                icon: nav5,
                get title() { return i18n.t('General_'); },
                href: SETTINGS_ROUTE,
                routes: [{ path: SETTINGS_ROUTE }],
                subitems: []
            },
            {
                key: "pbx",
                icon: nav5,
                get title() { return i18n.t('PBX.setup_'); },
                href: PBX_ROUTE,
                routes: [{ path: PBX_ROUTE }, { path: SETTINGS_GUIDE_3CX_ROUTE }],
                subitems: []
            },
            {
                key: "recording-extension",
                icon: nav5,
                get title() { return i18n.t('Browser.Extension_'); },
                href: RECORDING_EXTENSION_ROUTE,
                routes: [{ path: RECORDING_EXTENSION_ROUTE }],
                subitems: []
            },
            {
                key: "integrations",
                icon: nav5,
                get title() { return i18n.t('Integrations_'); },
                href: INTEGRATIONS_ROUTE,
                routes: [{ path: INTEGRATIONS_ROUTE }],
                subitems: []
            },
            {
                key: "api-keys",
                icon: nav5,
                get title() { return i18n.t('Speech.to.text.api_'); },
                href: API_KEYS_ROUTE,
                routes: [{ path: API_KEYS_ROUTE }],
                subitems: []
            },
            {
                key: "device",
                icon: nav5,
                get title() { return i18n.t('Apps_'); },
                href: DEVICE_ACTIVATE_ROUTE,
                routes: [{ path: DEVICE_ACTIVATE_ROUTE }, { path: DEVICE_ACTIVATE_ANDROID_ROUTE },
                    { path: DEVICE_ACTIVATE_ZOIPER_ANDROID_ROUTE }, { path: DEVICE_ACTIVATE_ZOIPER5_ROUTE }],
                subitems: []
            },
        ].filter(Boolean)
    },
    {
        key: 'documentation',
        icon: nav7,
        get title() { return `${i18n.t('Need.help_')}?`; },
        href: '',
        routes: [{ path: '' }],
        subitems: [],
        onClick: (e) => {
            e.preventDefault();
            window.open(HELP_URL_FULL, "_blank");
        }
    },
];
export const RESELLER_SIDEBAR_MENU_ITEMS = [
    {
        key: 'reseller',
        icon: nav1,
        get title() { return i18n.t('My.customers_'); },
        href: RESELLER_CUSTOMERS_ROUTE,
        routes: [{ path: RESELLER_CUSTOMERS_ROUTE }, { path: RESELLER_CUSTOMERS_SETTINGS_ROUTE },
            { path: RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE }, { path: RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE }],
        subitems: []
    },
    {
        key: 'billing',
        icon: nav6,
        get title() { return i18n.t('Billing.history_'); },
        href: RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE,
        routes: [{ path: RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE }],
        subitems: []
    },
];
