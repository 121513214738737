import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { useAppSelector } from "src/app/store/hooks";
import { useMemo } from "react";
const useIsAccountFeatureEnabled = () => {
    const authUser = useAppSelector(selectAuthUser);
    return useMemo(() => {
        const featuresSet = new Set((authUser === null || authUser === void 0 ? void 0 : authUser.features) || []);
        return (feature) => featuresSet.has(feature);
    }, [authUser]);
    // return (feature: FeatureTypes) => authUser?.features?.includes(feature) || false;
};
export default useIsAccountFeatureEnabled;
