import { jsx as _jsx } from "react/jsx-runtime";
import { VoIpServerGuideRoutes } from "src/features/pbx/types";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";
const GuideLink = ({ serverType }) => {
    const guideRoute = VoIpServerGuideRoutes[serverType];
    if (!guideRoute)
        return;
    return (_jsx(Text, { size: "lg", mt: "md", children: _jsx(Trans, { i18nKey: "Click.here.for.Guide.how.to.setup.it.up_", components: [
                _jsx(Link, { to: guideRoute, target: "_blank", rel: "noopener noreferrer" }, "guide-link"),
            ] }) }));
};
export default GuideLink;
