import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import Header from "./Header";
import Title from "./Title";
import { Divider } from "@mantine/core";
import PbxSetup from "src/features/pbx/PbxSetup";
const PbxPage = () => {
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: [_jsx(Title, {}), _jsx(Divider, { size: "md", my: "sm" }), _jsx(PbxSetup, {})] }));
};
export default PbxPage;
